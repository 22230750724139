@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.Welcome {
    &.Section {
        display: flex;
        flex-direction: column;
        align-items: center;

        min-height: 100vh;
        padding: $dims_padding-large 0 0;

        background-image: url(../../assets/banner.jpg) !important;
        background-size: cover !important;
        background-position: center center !important;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            width: 100%;
            height: 100%;

            background: linear-gradient(to right, #{rgba(#FBFAF4, 1)}, #{rgba(#FBFAF4, .25)});
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 1;

            width: 100%;
            height: 100%;
            min-height: 0;
            padding: 0 $dims_padding-medium $dims_padding-medium;
            margin: auto 0;

            @include respond-to(tablet) {
                padding: 0 $dims_padding-large $dims_padding-medium;
            }

            p {
                max-width: 25em;
                font-size: font.$size--xlarge;
                font-family: $font_family-secondary;
                margin: 0;
                margin-right: auto;
                text-align: left;
            }

            h2, h3, p, .button {
                z-index: 1;
            }

            h2 {
                flex: 1;
                width: 60%;
                margin: auto auto 0 0;

                color: $color_black;
                font-family: $font_family-primary;
                font-size: $font_size-largest * .75;
                font-weight: 600;
                line-height: 1.2em;

                @include respond-to(tablet) {
                    font-size: $font_size-largest;
                }
            }

            h3 {
                display: none;
            }
        }

        .links {
            margin: $dims_padding-small 0;
            margin-right: auto;

            strong {
                display: inline-block;
                margin-top: layout.$padding;
                font-size: font.$size--large;
                font-weight: 600;
            }

            em {
                display: inline-block;
                margin-top: .3em;
                font-size: font.$size--large;
                font-weight: 500;
                font-style: normal;
            }

            p {
                font-size: $font_size-medium - 0.125;
            }
        }

        .button {
            display: block;

            width: max-content;
            margin: $dims_padding-medium 0;
            margin-right: auto;
            padding: $dims_padding-small $dims_padding-small * 2;

            color: $color_font-light;
            font-size: $font_size-medium;

            background: $color_brand;
            border-radius: $box_radius-large;
            transition: background .2s;
            
            &:hover {
                background: darken($color_brand, 1);
            }

            &:last-of-type {
                margin-bottom: $dims_padding-medium;
            }
        }
    }

    &-wrap {
        footer {
            display: none;
            position: absolute;
            bottom: 0em;

            margin: auto;

            font-size: $font_size-medium;
            text-align: center;

            background: none;
            cursor: pointer;
            opacity: 1;
            pointer-events: all;
            transition-delay: 1s;
            transition: opacity animation.$speed, transform animation.$speed;
            transform: translateY(0em);

            @include respond-to(tablet) {
                display: block;
            }

            &.hidden {
                opacity: 0;
                transform: translateY(-1em);
                transition-delay: 0s;
                pointer-events: none;
            }

            p {
                color: color.$white;
            }

            &:hover {
                svg {
                    transform: translateY(.1em);
                }
            }

            svg {
                $size: $font_size-large * 3;

                width: $size;
                height: $size;
                margin: auto;
                color: $color_brand;
                transform: translateY(0em);
                transition: transform animation.$speed * .5;
            }
        }
    }

    .engagement-animation {
        @extend ._vertical-align;
        display: none;
        z-index: 0;

        right: 10%;

        width: 35%;
        max-width: 450px;
        height: 50%;

        @include respond-to(tablet) {
            display: block;
        }

        .Callout {
            position: absolute;
            background: $color_yellow;
            opacity: 0;
            transform: scale(0);

            &:before {
                right: 2rem;
                border-top-color: $color_yellow;
            }

            &:nth-of-type(1) {
                top: 0;
                left: 0;
            }

            &:nth-of-type(2) {
                bottom: 10%;
                right: -20%;

                &:before {
                    top: -.5rem;
                    left: 2rem;
                    right: unset;
                    bottom: unset;
                    transform: rotateX(180deg);
                }
            }
        }

        .Pin {
            position: absolute;
            opacity: 0;
            transform: translateY(-30px);

            span {
                opacity: 0;
            }

            &.live {
                img { background: #EE9C22; }
                span { background: #FFE0B3; }
                &:after { background: #EE9C22; }
            }

            &.work {
                img{ background: #F27D77; }
                span { background: #FFD8D6; }
                &:after {
                    background: #F27D77;
                }
            }

            &.walk {
                img { background: #5EAB8F; }
                span { background: #D6F5EA; }
                &:after {
                    background: #5EAB8F;
                }
            }

            &.entertainment {
                img { background: #5EAB8F; }
                span { background: #D6F5EA; }
                &:after {
                    background: #5EAB8F;
                }
            }
        }

        .connected-pins {
            position: absolute;
            right: 0;
            top: 0;
            width: 80%;
            height: 100%;

            &:before, &:after {
                content: '';
                z-index: 1;
                position: absolute;
                display: block;
                height: 25px;
                width: 25px;
                border-radius: 50%;
                background: $color_brand;
                transform: scale(0);
            }

            &:before {
                bottom: 0;
                left: 0;
            }

            &:after {
                content: '';
                top: 30%;
                right: 1.5%;
            }

            > div {
                height: 100% !important;
                width: 100% !important;
            }

            .path-wrapper {
                top: 18%;
                width: 0%;
                height: 100%;
                overflow: hidden;
            }
        }

        &.engage {
            $anim-dur: .6s;
            $swoop-anim-dur: .5s;

            .Callout {
                animation: popIn $anim-dur forwards;

                &:nth-of-type(2) {
                    animation-delay: $anim-dur * .75;
                }
            }

            .Pin {
                animation: dropIn $anim-dur forwards;

                span {
                    animation: swoopIn $swoop-anim-dur forwards;
                }

                &.live {
                    left: 10%;
                    top: 25%;

                    span {
                        animation-delay: $anim-dur / 2;
                    }
                }

                &.work {
                    animation-delay: $anim-dur / 2;
                    left: 40%;
                    top: 10%;

                    span {
                        animation-delay: $anim-dur;
                    }
                }

                &.walk {
                    animation-delay: $anim-dur;
                    bottom: 15%;
                    left: -4%;
                    span {
                        animation-delay: $anim-dur * 1.5;
                    }
                }

                &.entertainment {
                    animation-delay: $anim-dur * 1.2;
                    top: 7%;
                    right: -1%;
                    span {
                        animation-delay: $anim-dur * 1.7;
                    }
                }
            }

            .connected-pins {
                &:before {
                    animation: blipIn .5s forwards;
                    animation-delay: $anim-dur * 2;
                }

                &:after {
                    animation: blipIn .5s forwards;
                    animation-delay: $anim-dur * 2.3;
                }

                .path-wrapper {
                    animation: swipeIn 1s forwards;
                    animation-delay: $anim-dur * 3;
                }
            }
        }
    }

}
